import React, { forwardRef, useImperativeHandle, useState } from 'react';
import { getCurrentMoment, getMinMoment, getMoment } from '@util/date';

import { DateInput } from 'nhsuk-react-components';

const Date = forwardRef(({ question, onChange, formData }, ref) => {
    const [error, setError] = useState(undefined);
    const [isValid, setIsValid] = useState(undefined);
    const validateDateInput = (dateInput, dateType) => {
        if (/^\d+$/.test(dateInput)) {
            switch (dateType) {
                case 'day':
                    question.value.day = dateInput.slice(0, 2);
                    break;
                case 'month':
                    question.value.month = dateInput.slice(0, 2);
                    break;
                case 'year':
                    question.value.year = dateInput.slice(0, 4);
                    break;
                default:
                    break;
            }
        } else if (/[^0-9]/.test(dateInput)) {
            switch (dateType) {
                case 'day':
                    question.value.day = dateInput.replace(/[^0-9]/g, '');
                    validateDateInput(question.value.day, 'day');
                    break;
                case 'month':
                    question.value.month = dateInput.replace(/[^0-9]/g, '');
                    validateDateInput(question.value.month, 'month');
                    break;
                case 'year':
                    question.value.year = dateInput.replace(/[^0-9]/g, '');
                    validateDateInput(question.value.year, 'year');
                    break;
                default:
                    break;
            }
        }
    };
    const validateDate = () => {
        const moment = getMoment(question.value);
        if (!moment.isValid() || !question.value.year) {
            return invalidate('Please enter a valid date');
        }

        setError(undefined);
        setIsValid(true);

        const minDate = question.min_date;
        var maxDate = question.max_date ? checkMaxDate(question.max_date) : question.max_date;
        const minAge = question.min_age;
        const dateLink = question.date_link;
        const questionName = question.name;

        if (!validateDateRange(minDate, maxDate, questionName)) return;
        if (!validateFutureDate(minDate, questionName)) return;
        if (!validateMaxDate(maxDate, questionName)) return;
        if (!validatePastDate(question.only_allow_past_date, questionName)) return;
        if (!validateMinAge(minAge, questionName)) return;
        if (dateLink) validateDateLink(dateLink, moment);
    };

    const checkMaxDate = maxDate => {
        if (isDate(maxDate)) {
            return maxDate;
            // Breaks down the expression and generates a corresponding max date
            // e.g. "today +3 months" will output a date the is 3 months ahead of the current date
        } else {
            var expression = maxDate.split(' ');
            var dynMaxDate;
            const [symbol, num] = splitNumberSymbol(expression[1]);
            if (expression[0] === 'today' || expression[0] === 'now') {
                dynMaxDate = getCurrentMoment();
            }
            if (symbol === '+') {
                dynMaxDate.add(num, expression[2]);
            } else if (symbol === '-') {
                dynMaxDate.subtract(num, expression[2]);
            }
            return dynMaxDate.format('YYYY-DD-MM');
        }
    };

    // Splits +3 into symbol and number, it has to be any single symbol, and any whole number (any number of digits)
    function splitNumberSymbol(str) {
        const match = str.match(/^([+-])(\d+)$/);
        if (!match) throw new Error(`function splitNumberSymbol - Invalid format: ${str}`);
        return [match[1], parseInt(match[2], 10)];
    }

    function isDate(value) {
        return /^\d{4}-\d{2}-\d{2}$/.test(value);
    }

    const invalidate = message => {
        setError(message);
        setIsValid(false);
        return false;
    };

    const validateDateRange = (minDate, maxDate, questionName) => {
        if (minDate && maxDate && !isInDateRange()) {
            return invalidate(`${questionName} must be between ${minDate} and ${maxDate}`);
        }
        return true;
    };

    const validateFutureDate = (minDate, questionName) => {
        if (minDate && !isFutureDate()) {
            return invalidate('Enter a later date for ' + questionName);
        }
        return true;
    };

    const validateMaxDate = (maxDate, questionName) => {
        if (maxDate && !isMaxDate(maxDate)) {
            return invalidate('Enter an earlier date for ' + questionName);
        }
        return true;
    };

    const validatePastDate = (onlyAllowPast, questionName) => {
        if (onlyAllowPast && !isPastDate()) {
            return invalidate('Future dates are not allowed for ' + questionName);
        }
        return true;
    };

    const validateMinAge = (minAge, questionName) => {
        if (minAge && !isMinimumAge()) {
            return invalidate('The minimum required age for ' + questionName + ' is ' + minAge);
        }
        return true;
    };

    const validateDateLink = (dateLink, moment) => {
        var expression = dateLink.split(' '); //"dob 14 >" -> ['dob', '14', '>']
        const comparator = expression[2]; // e.g. <, >, ==
        const offset = +expression[1]; // e.g. 1, 4, 7
        const linkedQuestion = findQuestionById(expression[0]);
        const linkedDate = getMoment(linkedQuestion.value);

        if (offset) {
            try {
                linkedDate.add(offset, 'y');
            } catch (error) {
                throw new Error(`Unexpected offset: ${error}`);
            }
        }

        if (!compareDates(linkedDate, comparator, moment, linkedQuestion.name, offset)) {
            setIsValid(false);
        }
    };

    const compareDates = (linkedDate, comparator, moment, linkedName, offset) => {
        const offsetText = offset ? ` at least ${offset} years after the` : ' greater than';
        const messages = {
            '>': `The date for this question must be${offsetText} ${linkedName}`,
            '<': `The date for this question must be less than ${linkedName}`,
            '==': `The date for this question must be equal to ${linkedName}`
        };
        if (
            (comparator === '>' && linkedDate > moment) ||
            (comparator === '<' && linkedDate < moment) ||
            (comparator === '==' && linkedDate !== moment)
        ) {
            return invalidate(messages[comparator]);
        }
        return true;
    };

    function findQuestionById(targetId) {
        return formData.flatMap(section => section.questions).find(q => q.id === targetId);
    }

    const validate = () => {
        if (question.value == null) {
            if (question.mandatory) {
                setError('Please enter a valid date');
                setIsValid(false);
            } else {
                setError(undefined);
                setIsValid(true);
            }
        } else {
            validateDateInput(question.value.day, 'day');
            validateDateInput(question.value.month, 'month');
            validateDateInput(question.value.year, 'year');
            if (question.mandatory) {
                if (question.value.day && question.value.month && question.value.year) {
                    validateDate();
                } else {
                    setError('Enter the ' + question.name);
                    setIsValid(false);
                }
            } else {
                if (question.value.day || question.value.month || question.value.year) {
                    validateDate();
                } else {
                    setError(undefined);
                    setIsValid(true);
                }
            }
        }
    };

    useImperativeHandle(ref, () => ({
        validate,
        isValid
    }));

    const isPastDate = () => {
        return getMoment(value).isBefore(getCurrentMoment().subtract(1, 'days'));
    };

    const isFutureDate = () => {
        const dateParts = question.min_date.split('-');
        const minDate = getMoment({ day: dateParts[1], month: dateParts[2], year: dateParts[0] });
        return getMoment(value).isAfter(minDate);
    };

    const isMaxDate = maxDateInput => {
        const dateParts = maxDateInput.split('-');
        const maxDate = getMoment({ day: dateParts[1], month: dateParts[2], year: dateParts[0] });
        return getMoment(value).isBefore(maxDate);
    };

    const isInDateRange = () => {
        const maxDateParts = question.max_date.split('-');
        const maxDate = getMoment({
            day: maxDateParts[1],
            month: maxDateParts[2],
            year: maxDateParts[0]
        });

        const minDateParts = question.min_date.split('-');
        const minDate = getMoment({
            day: minDateParts[1],
            month: minDateParts[2],
            year: minDateParts[0]
        });

        return getMoment(question.value).isBetween(minDate, maxDate);
    };

    const isMinimumAge = () => {
        const minBirthdate = getMinMoment(question.min_age);
        return (
            getMoment(question.value).isValid() &&
            getMoment(question.value).isBefore(getCurrentMoment()) &&
            getMoment(question.value).isSameOrBefore(minBirthdate)
        );
    };

    const handleOnChange = async event => {
        const inputName = event.target.id.split('-').slice(-1);
        var date = getValue();
        date[inputName] = event.target.value;
        question.value = date;
        await onChange({
            questionId: question.id,
            value: date
        });

        await validate(date);
    };

    function getValue() {
        return (
            question.value ?? {
                day: '',
                month: '',
                year: ''
            }
        );
    }

    const value = getValue();

    return (
        <DateInput id={question.id} label={question.name} hint={question.hint_text} error={error}>
            <DateInput.Day
                value={value.day}
                onChange={handleOnChange}
                onBlur={handleOnChange}
                required={question.mandatory}
            />
            <DateInput.Month
                value={value.month}
                onChange={handleOnChange}
                onBlur={handleOnChange}
                required={question.mandatory}
            />
            <DateInput.Year
                value={value.year}
                onChange={handleOnChange}
                onBlur={handleOnChange}
                required={question.mandatory}
            />
        </DateInput>
    );
});

export default Date;
