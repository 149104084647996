import { Button, Table } from 'nhsuk-react-components';
import { FilterToggleButton, Filters, PaginationContainer, TableHeader } from '@table';
import { Link, useParams } from 'react-router-dom';
import Api from '@services/api';
import { CollectionSummaryTableCell } from '@components/collection';
import React, { useEffect, useState, useRef } from 'react';
import ReactHtmlParser from 'react-html-parser';
import { SubmissionWindowInfo } from '@submission';
import { isOpen } from '@util/submission-window';
import { nanoid } from 'nanoid';
import { orderBy } from 'lodash';
import styles from './collection-summary.module.css';

const CollectionSummary = ({ collection, orgCode, onCreateNewRecord, onLoadSubmission }) => {
    const [isLoading, setIsLoading] = useState(true);
    const [isFilterOpen, setIsFilterOpen] = useState(
        sessionStorage.getItem('isFilterOpen') === 'true' &&
            sessionStorage.getItem('collectionID') === collection.name
    );
    const [paging, setPaging] = useState({
        currentPage: 0,
        isPaginationRequired: false,
        numPages: 0,
        pageEndIndex: 0,
        pageStartIndex: 0,
        resultsPerPage: 50
    });
    const [submissionsDataCache, setSubmissionsDataCache] = useState([]);
    const [submissionsData, setSubmissionsData] = useState([]);
    const { collection: collectionParam } = useParams();
    const columnSpecsRef = useRef([]);
    const isMounted = useRef(true);
    const [lastLoginDate, setlastLoginDate] = useState('none');
    useEffect(() => {
        const api = new Api();
        const fetchData = async () => {
            try {
                const submissionsResponse = await api.getSubmissions(orgCode);
                if (isMounted.current) {
                    const lastLoginResponse = await api.getLatestLoginLog(
                        orgCode,
                        collection.name,
                        'Collection Access'
                    );
                    await api.postAuditLog(orgCode, collection.name, 'Collection Access');
                    if (lastLoginResponse.data.last_login !== undefined) {
                        setlastLoginDate(lastLoginResponse.data.last_login);
                    }
                    setSubmissionsDataCache(submissionsResponse.data.submissions);
                    setSubmissionsData(
                        sessionStorage.getItem('collectionID') === collection.name
                            ? submissionsData
                            : submissionsResponse.data.submissions
                    );
                    setIsLoading(false);
                    columnSpecsRef.current = enrichColumnSpecs();
                    setPagingState(1, submissionsResponse.data.submissions);
                }
            } catch {
                if (isMounted.current) {
                    setSubmissionsData([]);
                }
            }
        };

        fetchData();

        return () => {
            isMounted.current = false;
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [collection, orgCode]);

    const handleFilterToggle = e => {
        e.preventDefault();
        e.target.blur();
        setIsFilterOpen(prevState => !prevState);
        sessionStorage.setItem('isFilterOpen', JSON.stringify(!isFilterOpen));
    };

    const setPagingState = (setPageNum, unSetSubsData) => {
        const dataToUse = unSetSubsData || submissionsData;
        const isPaginationRequired = dataToUse.length > paging.resultsPerPage;
        let numPages, pageStartIndex, pageEndIndex;

        if (isPaginationRequired) {
            numPages = Math.ceil(dataToUse.length / paging.resultsPerPage);

            if (typeof setPageNum === 'string') {
                setPageNum = parseInt(setPageNum);
            }
            if (setPageNum > numPages) {
                setPageNum = numPages;
            }

            if (setPageNum && setPageNum > 1) {
                pageStartIndex = (setPageNum - 1) * paging.resultsPerPage;
                pageEndIndex = pageStartIndex + (paging.resultsPerPage - 1);
                if (pageEndIndex > dataToUse.length - 1) {
                    pageEndIndex = dataToUse.length - 1;
                }
            } else {
                setPageNum = 1;
                pageStartIndex = 0;
                pageEndIndex = paging.resultsPerPage - 1;
            }
        } else {
            numPages = 0;
            pageStartIndex = 0;
            pageEndIndex = dataToUse.length - 1;
        }

        setPaging(prevPaging => ({
            ...prevPaging,
            currentPage: setPageNum,
            isPaginationRequired: isPaginationRequired,
            numPages: numPages,
            pageEndIndex: pageEndIndex,
            pageStartIndex: pageStartIndex
        }));
    };

    const enrichColumnSpecs = () => {
        const columnSpecs = collection.submission_summary;

        columnSpecs.forEach(columnSpec => {
            if (columnSpec.section_id) {
                const sectionSpec = collection.form.sections.find(
                    x => x.id === columnSpec.section_id
                );

                if (columnSpec.question_id) {
                    const questionSpec = getAllQuestionSpecs(sectionSpec).find(
                        x => x.id === columnSpec.question_id
                    );

                    columnSpec.type = questionSpec.type;
                    columnSpec.options = questionSpec.options;
                }

                if (columnSpec.question_ids) {
                    columnSpec.questions = columnSpec.question_ids.map(questionId => {
                        const questionSpec = getAllQuestionSpecs(sectionSpec).find(
                            x => x.id === questionId
                        );

                        return {
                            id: questionId,
                            type: questionSpec.type,
                            options: questionSpec.options
                        };
                    });
                }
            } else {
                switch (columnSpec.id) {
                    case 'created_at':
                        columnSpec.type = 'date';
                        break;
                    case 'updated_at':
                        columnSpec.type = 'date';
                        break;
                    case 'status':
                        columnSpec.type = 'status';
                        break;
                    case 'id':
                        columnSpec.type = 'id';
                        break;
                    case 'org_name':
                        columnSpec.type = 'org_name';
                        break;
                    case 'actions':
                        columnSpec.type = 'actions';
                        break;
                    default:
                        columnSpec.type = undefined;
                }
            }
        });

        return columnSpecs;
    };

    const getAllQuestionSpecs = sectionSpec => {
        const questionSpecs = sectionSpec.questions;
        const conditionalRevealQuestionSpecs = sectionSpec.questions
            .filter(x => x.options)
            .flatMap(x => x.options)
            .filter(x => x.questions)
            .flatMap(x => x.questions);

        return [...questionSpecs, ...conditionalRevealQuestionSpecs];
    };

    const handleClick = () => {
        onCreateNewRecord();
    };

    const onViewClick = submission => {
        onLoadSubmission({
            orgCode: submission.org_code,
            render: 'view-submission',
            submissionId: submission.id
        });
    };

    const onEditClick = submission => {
        onLoadSubmission({
            orgCode: submission.org_code,
            render: 'form',
            submissionId: submission.id
        });
    };

    const getFormUrl = () => {
        const section = collection.form.sections[0];
        return `/${collectionParam}/${section.id}`;
    };

    const getViewSubmissionUrl = submission => `/${collectionParam}/submission/${submission.id}`;

    const sortSubmissions = (sortField, sortDirection) => {
        setPagingState(1);

        const sortedSubmissionsData = orderBy({ ...submissionsData }, sortField, sortDirection);

        setSubmissionsData(sortedSubmissionsData);
    };

    if (isLoading) {
        return 'Loading...';
    }

    const enableViewSubmissionPage = collection.enable_view_submission_page;
    const isHideEdit = collection.hide_edit_start_column;
    const showViewColumn =
        enableViewSubmissionPage && !collection.submission_summary.some(x => x.id === 'id');
    const isSubmissionWindowOpen = isOpen(collection);

    const tableBodyRows =
        submissionsData.length > 0
            ? submissionsData
                  .slice(paging.pageStartIndex, paging.pageEndIndex + 1)
                  .map(submission => (
                      <Table.Row key={nanoid()}>
                          {columnSpecsRef.current.map(spec => {
                              const value = submission[spec.id]?.id
                                  ? submission[spec.id]?.value
                                  : submission[spec.id];

                              const columnSpec =
                                  spec.questions?.find(x => x.id === submission[spec.id]?.id) ??
                                  spec;

                              return (
                                  <CollectionSummaryTableCell
                                      key={nanoid()}
                                      submission={submission}
                                      collection={collection}
                                      value={value}
                                      columnSpec={columnSpec}
                                      enableViewSubmissionPage={enableViewSubmissionPage}
                                      onViewClick={onViewClick}
                                  />
                              );
                          })}

                          {showViewColumn && (
                              <Table.Cell>
                                  {submission.status !== 'not-started' && (
                                      <Link
                                          to={getViewSubmissionUrl(submission.id)}
                                          onClick={() => onViewClick(submission)}
                                      >
                                          View
                                      </Link>
                                  )}
                              </Table.Cell>
                          )}
                          {isSubmissionWindowOpen && (
                              <Table.Cell>
                                  {!isHideEdit && (
                                      <Link
                                          to={getFormUrl()}
                                          onClick={() => onEditClick(submission)}
                                      >
                                          {submission.status === 'not-started' ? 'Start' : 'Edit'}
                                      </Link>
                                  )}
                              </Table.Cell>
                          )}
                      </Table.Row>
                  ))
            : null;

    const headerHtml = collection.submission_summary_header
        ? atob(collection.submission_summary_header)
        : undefined;

    return (
        <div className="nhsuk-grid-row">
            <div className="nhsuk-grid-column-full">
                <h1>{collection.name}</h1>
                {/* Last Login date is only meant to show for the 3 NWRS collections, as of 06/03/2025 */}
                {[
                    'NHS Workforce GP Practice Staff Details',
                    'NHS Workforce PCN Staff Details',
                    'PCN Contracted Services'
                ].includes(collection.name) && lastLoginDate !== 'none' ? (
                    <p style={{ fontWeight: 'bold' }}>Last login date: {lastLoginDate}</p>
                ) : null}
                <SubmissionWindowInfo collection={collection} />
                {ReactHtmlParser(headerHtml)}
                {isSubmissionWindowOpen ? (
                    <Filters
                        className={`${styles.collectionSummaryFilter} nhsuk-u-margin-right-4`}
                        isFilterOpen={isFilterOpen}
                        setSubmissionData={setSubmissionsData}
                        submissionSummary={collection.submission_summary}
                        submissionsDataCache={submissionsDataCache}
                        collectionID={collection.name}
                    />
                ) : null}
                <div className={styles.collectionSummaryTable}>
                    {isSubmissionWindowOpen && (
                        <Button onClick={handleClick}>Create New Record</Button>
                    )}
                    {isSubmissionWindowOpen && submissionsData.length > 1 ? (
                        <span className="nhsuk-u-margin-left-4">
                            <FilterToggleButton
                                handleFilterToggle={handleFilterToggle}
                                isFilterOpen={isFilterOpen}
                            />
                        </span>
                    ) : null}
                    {isSubmissionWindowOpen && submissionsData.length ? (
                        <>
                            <Table>
                                <TableHeader
                                    columnSpecs={columnSpecsRef.current}
                                    sortSubmissions={sortSubmissions}
                                    isSubmissionWindowOpen={isSubmissionWindowOpen}
                                    showViewColumn={showViewColumn}
                                    actionColumnText={'Action'}
                                    isHideEdit={isHideEdit}
                                />
                                <Table.Body>{tableBodyRows}</Table.Body>
                            </Table>
                            <PaginationContainer
                                handlePageClick={setPagingState}
                                paging={paging}
                                results={submissionsData}
                            />
                        </>
                    ) : (
                        <div>
                            <p>No records found</p>
                            <hr />
                        </div>
                    )}
                </div>
            </div>
        </div>
    );
};

export default CollectionSummary;
